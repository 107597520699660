import React from "react";
import { useLocation } from 'react-router-dom'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useEffect, useState } from "react";

export const PrivacyPolicy = () => {
  const location = useLocation();
  const language = location?.pathname.split('/')[1] ?? "en";
  const [response, setResponse] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}privacy-policy`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "accept-language": language || "en",
        "Token": process.env.REACT_APP_API_GENERAL_TOKEN,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setResponse(res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [language]);

  return (
    <>
      <Header props={"privacyPolicy"} />
      <div className="container mt-4">
        <h1 className="mb-3">
          <b>{response.title}</b>
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: response.body,
          }}
        ></div>
      </div>
      <Footer />
    </>
  );
};
