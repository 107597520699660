import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LanguageContextProvider } from "contexts";
import { Home } from "pages";
import "assets/styles/App.scss";
import { PrivacyPolicy } from "./pages/Privacy&Policy/PrivacyPolicy";

const Container = ({ children }) => {
  return <LanguageContextProvider>{children}</LanguageContextProvider>;
};

function App() {
  return (
    <Fragment>
      <Routes>
      <Route
          path="/"
          element={
            <Container>
              <Home />
            </Container>
          }
        />
        <Route
          path="/:lang/"
          element={
            <Container>
              <Home />
            </Container>
          }
        />
        <Route
          path="/:lang/privacy-policy"
          element={
            <Container>
              <PrivacyPolicy />
            </Container>
          }
        />
         <Route
          path="/privacy-policy"
          element={<Navigate to="/en/privacy-policy" replace />}
        />
      </Routes>
    </Fragment>
  );
}

export default App;
