import React from "react";
// import { useLanguage } from "hooks";
// import { Link } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav, Button } from "react-bootstrap";
import { FiSmartphone } from "react-icons/fi";
import { useLanguage } from "hooks";
import "./Header.scss";
import { Link } from "react-router-dom";

function Header(props) {
  const { language, translation, handleSwitchLanguage } = useLanguage();

  const navLinks = [
    {
      title: translation.features,
      link: "#Features",
    },
    {
      title: translation.download,
      link: "#Download-app",
    },
    {
      title: translation.uIscreens,
      link: "#UI-screens",
    },
    {
      title: translation.contactUs,
      link: "#contact-us",
    },
  ];
  return (
    <div
      id="header"
      className="py-3"
      class={props.props == "privacyPolicy" && "privacy"}
    >
      <Container>
        <Row>
          <Col xs={4} sm={4} md={3} lg={2} xl={2}>
            {language === "ar" ? (
              <Link to="/">
                <img
                  src="/Images/logoArabic.png"
                  alt="logo"
                  className="img12"
                  width="90%"
                  height="auto"
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src="/Images/logo.png"
                  alt="logo"
                  className="img12"
                  width="90%"
                  height="auto"
                />
              </Link>
            )}
          </Col>
          <Col
            xs={8}
            sm={8}
            md={9}
            lg={10}
            xl={10}
            className="align-self-center"
          >
            <div className="contant">
              <Row className="align-items-center">
                <Col xs={12} lg={7} xl={8}>
                  <div className="header-navigation">
                    <Navbar
                      bg="transparent"
                      expand="lg"
                      className="navbar justify-content-end"
                    >
                      <Navbar.Toggle
                        color="white"
                        aria-controls="basic-navbar-nav"
                      />
                      <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="text-end px-3 py-2 rounded-3 justify-content-end"
                      >
                        {props.props != "privacyPolicy" && (
                          <Nav className="text-start">
                            {navLinks.map((item) => (
                              <Nav.Link href={item.link}>{item.title}</Nav.Link>
                            ))}
                          </Nav>
                        )}
                        <div className="d-block d-lg-none mt-3 text-start">
                          <Button
                            // as={Link}
                            // to={`/${language === "ar" ? "en" : "ar"}`}
                            onClick={() => {handleSwitchLanguage(props.props)}}
                            variant="primary"
                            className="w-100"
                          >
                            <div>
                              <h5 className="text-white ">
                                {language === "ar" ? "English" : "عربي"}
                              </h5>
                            </div>
                          </Button>
                        </div>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </Col>
                <Col xs={12} lg={4} xl={3} className="d-none d-lg-block">
                  <div className="tell">
                    <a href="tel:+962 79 884 38 24">
                      <div className="d-flex align-items-center justify-content-end">
                        <FiSmartphone className="text-primary fs-3 contact" />
                        <h5 className="text-white mt-1 contact" dir="ltr">
                          +962 79 884 38 24
                        </h5>
                      </div>
                    </a>
                  </div>
                </Col>
                <Col xs={12} lg={1} xl={1} className="lang d-none d-lg-block">
                  <div className="langButton">
                    <Button
                      // as={Link}
                      // to={`/${language === "ar" ? "en" : "ar"}`}
                      onClick={() => {handleSwitchLanguage(props.props)}}
                      variant="primary"
                    >
                      <div>
                        <h5 className="text-white ">
                          {language === "ar" ? "English" : "عربي"}
                        </h5>
                      </div>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {props.props != "privacyPolicy" && (
          <>
            <Row className="mt-5 mt-lg-0">
              <Col
                xs={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                className="align-self-center"
              >
                <Row>
                  <Col xs={12} className="">
                    <h2 className="text-white">
                      {
                        translation.theBestAppForMakingYouSucceedAndPassYourMedicalExams
                      }
                    </h2>
                  </Col>
                  <Col xs={12} className="mt-4 start">
                    <h2 className="text-white fw-bolder">
                      {translation.DownloadOurApp}
                    </h2>
                    <div className="header-download-btns-container d-flex flex-wrap mt-3">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.merwas.medicojo"
                        className="text-black"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="header-download-btn">
                          <div>
                            <img src="/Images/google1.png" alt="Apple" />
                          </div>
                          <div>
                            <h6 className="header-download-btn-subtitle">
                              {translation.getItOn}
                            </h6>
                            <h5 className="header-download-btn-title fw-bolder">
                              {translation.GooglePlay}
                            </h5>
                          </div>
                        </div>
                      </a>
                      <a
                        href="https://apps.apple.com/jo/app/medicojo/id6443721885"
                        className="text-black"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="header-download-btn">
                          <div>
                            <img src="/Images/Apple.png" alt="Apple" />
                          </div>
                          <div>
                            <h6 className="header-download-btn-subtitle">
                              {translation.getItOn}
                            </h6>
                            <h5 className="header-download-btn-title fw-bolder">
                              {translation.AppleStore}
                            </h5>
                          </div>
                        </div>
                      </a>
                      <div class="breaker"></div>
                      <a
                        href={
                          "https://medicojo.com/download/medicojo_desktopapp.zip?v=" +
                          Math.random()
                        }
                        className="text-black"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="header-download-btn">
                          <div>
                            <img
                              src="/Images/desktop-windows.png"
                              alt="Apple"
                            />
                          </div>
                          <div>
                            <h6 className="header-download-btn-subtitle">
                              {translation.getItFor}
                            </h6>
                            <h5 className="header-download-btn-title fw-bolder">
                              {translation.desktop}
                            </h5>
                          </div>
                        </div>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                className="d-none d-md-block"
              >
                <div>
                  <img
                    src="Images/rightImg2.png"
                    alt="mob"
                    width="100%"
                    height="auto"
                  />
                </div>
              </Col>
              <Col className="lite">
                <img
                  src="/Images/lite.png"
                  alt="logo"
                  className="img12"
                  width="auto"
                  height="auto"
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}

export default Header;
