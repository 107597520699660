import { useLanguageContext } from "contexts";
import { ar, en } from "languages";

export default function useLanguage() {
  const { language, handleSwitchLanguage } = useLanguageContext();

  const langaugeMap = {
    en: en,
    ar: ar,
  };

  return {
    language,
    translation: langaugeMap[language],
    handleSwitchLanguage,
  };
}
