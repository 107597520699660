import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.scss";

function Footer() {
  return (
    <div id="Footer">
      <Container>
        <Row>
          <Col xs={12} dir="ltr">
            <h4 className="title">© All rights reserved. medicojo.com</h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
