import React, { useMemo, useState } from "react";
import { Layout, Cards } from "components";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { useCountDown, useLanguage } from "hooks";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "./Home.scss";
import "swiper/css";
import "swiper/css/navigation";

const SlideItem = ({ slide, index }) => {
  const swiperSlide = useSwiperSlide();

  const getScaleY = useMemo(() => {
    if (swiperSlide.isActive) {
      return 1.3;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 1.15;
    }

    if (swiperSlide.isVisible) {
      return 1.05;
    }

    return 0.2;
  }, [
    swiperSlide.isActive,
    swiperSlide.isNext,
    swiperSlide.isPrev,
    swiperSlide.isVisible,
  ]);

  const getTiming = useMemo(() => {
    if (swiperSlide.isActive) {
      return 0.25;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 0.35;
    }

    return 0.35;
  }, [swiperSlide.isActive, swiperSlide.isNext, swiperSlide.isPrev]);

  const getZindex = useMemo(() => {
    if (swiperSlide.isActive) {
      return 1300;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 1200;
    }

    return 1100;
  }, [swiperSlide.isActive, swiperSlide.isNext, swiperSlide.isPrev]);

  return (
    <div>
      <img
        src={slide}
        alt="slide"
        className={`${swiperSlide.isActive ? "z-10000" : "z-1"}`}
        style={{
          position: "relative",
          transition: `all ${getTiming}s ease-in-out`,
          transform: `scale(${getScaleY}, ${getScaleY})`,
          zIndex: getZindex,
          opacity: swiperSlide.isVisible === true ? 1 : 0,
          borderRadius: "15px",
        }}
      />
    </div>
  );
};

function Home() {
  const { language, translation } = useLanguage();
  const [days, hours, minutes, seconds] = useCountDown("2024-10-17");
  const [newsLetterEmail, setNewsLetterEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  // const [ShowModalVIsible, setModalVIsible] = useState(false);

  const slides = [
    "/Images/screens/Splash.png",
    "/Images/screens/Home.png",
    "/Images/screens/Account.png",
    "/Images/screens/Dr-Amjad-Afeef.png",
    "/Images/screens/E-Books.png",
    "/Images/screens/E-Books2.png",
    "/Images/screens/Flash1.png",
    "/Images/screens/Flash2.png",
    "/Images/screens/Intro1.png",
    "/Images/screens/Intro2.png",
    "/Images/screens/Intro2–1.png",
    "/Images/screens/language-and-country.png",
    "/Images/screens/My-E-books-Subscriptions.png",
    "/Images/screens/My-Packages-Subscriptions.png",
    "/Images/screens/News-and-Article.png",
    "/Images/screens/Question-Bank-Un-Timed-Mode.png",
    "/Images/screens/Question-Bank-2-4.png",
    "/Images/screens/Rating-Points.png",
    "/Images/screens/Sign-In.png",
    "/Images/screens/Sign-Up.png",
    "/Images/screens/Statistics-1.png",
  ];

  const submitSubscribeToNewsLetter = (e) => {
    e.preventDefault();

    if (!isValidEmail || newsLetterEmail.length === 0) return;

    fetch("https://api.medicojo.com/api/submitNewsletterEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: "5850bb1be48c538d027be44ec81c198962f2f7ff",
      },
      body: JSON.stringify({
        email: newsLetterEmail,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setNewsLetterEmail("");
        // setModalVIsible(true);
        Swal.fire({
          title: translation.sent,
          text: translation.subSent,
          icon: "success",
          confirmButtonText: translation.close,
        });
      })
      .catch((error) => {
        // setModalVIsible(true);
        Swal.fire({
          title: error.message,
          text: "Do you want to continue",
          icon: "error",
          // confirmButtonText: "Cool",
        });
      });
  };

  return (
    <Layout>
      <div id="home-page" className="">
        <Container>
          <div id="Features">
            <Row className="mt-4">
              <Col xs={12}>
                <h1 className="title">
                  {translation.WithMedicoJoYouWillGetHugeAdvantages}
                </h1>
              </Col>
            </Row>
            <div className="under-count">
              <div>
                <Row className="mt-5">
                  <Col xs={12} md={6}>
                    <img
                      src="/Images/clock.png"
                      alt="home1"
                      width="100%"
                      height="100%"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mt-5 mt-md-0">
                    <Row>
                      <Col xs={12}>
                        <h1 className="text-danger fw-bolder">
                          {translation.DontMissYourStudyPlans}
                        </h1>
                      </Col>
                      <Col xs={12}>
                        <h2 className="fw-bolder">
                          {translation.SetYourCountdownToUpcomingEventsAndExams}
                        </h2>
                      </Col>
                      <Col xs={12}>
                        <h4 className="mt-3">{translation.paragraph}</h4>
                      </Col>
                      <Col xs={12} className="mt-4">
                        <div className="clock">
                          <Row>
                            <Col xs={3} data-title="Days">
                              <div className="time">
                                <h1>{days}</h1>
                              </div>
                              <div className="mt-3">
                                <h6 className="fw-bolder">
                                  {translation.Days}
                                </h6>
                              </div>
                            </Col>
                            <Col xs={3} data-title="Hours">
                              <div className="time">
                                <h1>{hours}</h1>
                              </div>
                              <div className="mt-3">
                                <h6 className=" fw-bolder">
                                  {translation.Hours}
                                </h6>
                              </div>
                            </Col>
                            <Col xs={3} data-title="Minutes">
                              <div className="time">
                                <h1>{minutes}</h1>
                              </div>
                              <div className="mt-3">
                                <h6 className=" fw-bolder">
                                  {translation.Minutes}
                                </h6>
                              </div>
                            </Col>
                            <Col xs={3} data-title="Seconds">
                              <div className="time">
                                <h1>{seconds}</h1>
                              </div>
                              <div className="mt-3">
                                <h6 className=" fw-bolder">
                                  {translation.Seconds}
                                </h6>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <img
                src="/Images/underbg.png"
                alt="underbg"
                className="img-under-count"
              />
            </div>
            <div className="under-count">
              <div className="slider-content mt-5">
                <Row>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/Question-Bank.png"}
                      title={translation.QuestionBanks}
                      subTitle={translation.PargQuestionBanks}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/Videos.png"}
                      title={translation.VideosAndCourses}
                      subTitle={translation.PargVideosAndCourses}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/card.png"}
                      title={translation.Flashcards}
                      subTitle={translation.PargFlashcards}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <div className="mt-5">
                      <Card className="card-img border-0">
                        <img
                          src="/Images/rightImgCard.png"
                          alt="rightImgCard"
                          className="image mt-1"
                        />
                      </Card>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <div className="mt-5">
                      <Card className="card-img border-0">
                        <img
                          src="/Images/leftImgCard.png"
                          alt="leftImgCard"
                          className="image mt-3 mt-xl-1"
                        />
                      </Card>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/notes.png"}
                      title={translation.EBooks}
                      subTitle={translation.PargEBooks}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/rating.png"}
                      title={translation.PointsAndBalance}
                      subTitle={translation.PargPointsAndBalance}
                      width="200"
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/news.png"}
                      title={translation.NewsAndArticles}
                      subTitle={translation.PargNewsAndArticles}
                    />
                  </Col>
                </Row>
              </div>
              <img
                src="/Images/underbg.png"
                alt="underbg"
                className="img-under-count1"
              />
              <img
                src="/Images/underbg1.png"
                alt="underbg1"
                className="img-under-count2"
              />
            </div>
          </div>
        </Container>
        <div className="screens" id="UI-screens">
          <Container className="p-0">
            {/* fluid="md" */}
            <div className="text-center">
              <Row>
                <Col xs={12}>
                  <div className="title-screen">
                    <h1 data-title={translation.WatchOurUIPresentation}>
                      {translation.WatchOurUIPresentation}
                    </h1>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="h-100 py-5">
                    <Swiper
                      dir="ltr"
                      watchSlidesProgress
                      centeredSlides
                      centeredSlidesBounds
                      centerInsufficientSlides
                      // onActiveIndexChange={onSlideChangeHandler}
                      // onInit={onSlideChangeHandler}
                      // onSlideChange={onSlideChangeHandler}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        576: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 5,
                        },
                      }}
                      spaceBetween={0}
                      slidesPerGroup={1}
                      loop={true}
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {slides.map((slide, idx) => (
                        <SwiperSlide virtualIndex={idx}>
                          <SlideItem slide={slide} index={idx} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="under-count mt-5 pt-5">
          <div>
            <div className="download" id="Download-app">
              <Container>
                <div className="download-content pt-5">
                  <Row className="mt-5 g-3">
                    <Col xs={12}>
                      <h1
                        className="text-center fw-bolder"
                        data-title={translation.DownloadOurApp}
                      >
                        {translation.DownloadOurApp}
                      </h1>
                    </Col>
                    <Col xs={12}>
                      <h2 className="text-center">
                        {translation.titleDowload}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col
                      xs={12}
                      md={12}
                      lg={7}
                      className="align-self-center d-none d-lg-block"
                    >
                      <div className="image">
                        <img
                          src="/Images/download-app.png?v=1"
                          alt="Download-app"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} lg={5} className="align-self-center">
                      <div className="download-details">
                        <Row className="g-3">
                          <Col xs={12} md={5}>
                            <div className="qr-div text-center">
                              <img
                                src="/Images/qrCode2.png"
                                alt="qrCode"
                                className="qrimg"
                              />
                            </div>
                          </Col>
                          <Col xs={12} md={7}>
                            <Row className="gy-3">
                              <Col xs={12}>
                                <a
                                  href="https://apps.apple.com/jo/app/medicojo/id6443721885"
                                  className="text-black"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className="download-app-card text-center bg-white rounded-2 p-2">
                                    <div className="d-inline-flex gap-4 align-items-center justify-content-start">
                                      <div>
                                        <img
                                          src="/Images/Apple.png"
                                          alt="Apple"
                                        />
                                      </div>
                                      <div>
                                        <h6>{translation.getItOn}</h6>
                                        <h4 className="fw-bold">
                                          {translation.AppleStore}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Col>
                              <Col xs={12}>
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.merwas.medicojo"
                                  className="text-black"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className="download-app-card text-center bg-white rounded-2 p-2">
                                    <div className="d-inline-flex gap-4 align-items-center justify-content-start">
                                      <div>
                                        <img
                                          src="/Images/google1.png"
                                          alt="Google Play"
                                        />
                                      </div>
                                      <div>
                                        <h6>{translation.getItOn}</h6>
                                        <h4 className="fw-bold">
                                          {translation.GooglePlay}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Col>
                              <Col xs={12}>
                                <a
                                  href={
                                    "https://medicojo.com/download/medicojo_desktopapp.zip?v=" +
                                    Math.random()
                                  }
                                  className="text-black"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className="download-app-card text-center bg-white rounded-2 p-2">
                                    <div className="d-inline-flex gap-4 align-items-center justify-content-start">
                                      <div>
                                        <img
                                          src="/Images/desktop-windows.png"
                                          alt="Windows"
                                        />
                                      </div>
                                      <div>
                                        <h6>{translation.getItFor}</h6>
                                        <h4 className="fw-bold">
                                          {translation.desktop}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <div className="under-count">
          <div>
            <div className="news" id="contact-us">
              <Container>
                <Row>
                  <Col xs={12}>
                    <div className="news-letter">
                      <Row>
                        <Col xs={6} className="d-flex justify-content-center">
                          <img src="/Images/newsImg.png" alt="newsImg" />
                        </Col>
                        <Col xs={6} className="align-self-center">
                          <div className="news-details">
                            <h1
                              className="fw-bolder"
                              data-title={translation.NEWSLETTER}
                            >
                              {translation.NEWSLETTER}
                            </h1>
                            <h2 className="text-danger fw-bolder">
                              {translation.BeAwareOfNewFeatures}
                            </h2>
                            <div className="mt-3">
                              <Form>
                                <Form.Group>
                                  <Row>
                                    <Col xs={12} md={8}>
                                      <Form.Control
                                        type="email"
                                        onChange={(e) => {
                                          let email = e.target.value;
                                          const testEmail =
                                            /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
                                              email
                                            );
                                          setIsValidEmail(testEmail);
                                          setNewsLetterEmail(email);
                                        }}
                                        value={newsLetterEmail}
                                        placeholder={translation.Email}
                                        className={`input-email border border-1 ${
                                          !isValidEmail
                                            ? "is-invalid border-danger"
                                            : "border-0"
                                        }`}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={4}
                                      className="mt-3 mt-md-0"
                                    >
                                      <Button
                                        onClick={submitSubscribeToNewsLetter}
                                        variant="primary"
                                        type="submit"
                                        className="btn-email"
                                      >
                                        {translation.Subscribe}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="footer-logo">
                      <Row>
                        <Col xs={12}>
                          {language === "ar" ? (
                            <img src="/Images/logoArabic.png" alt="logo" />
                          ) : (
                            <img src="/Images/logo1.png" alt="logo" />
                          )}
                        </Col>
                        <Col xs={12}>
                          <div className="contact" dir="ltr">
                            <div>
                              <a href="tel:+962 79 884 38 24">
                                <div className="d-flex ">
                                  <BsTelephone className="icons" />
                                  <h4 className="text-white">
                                    +962 79 884 38 24
                                  </h4>
                                </div>
                              </a>
                            </div>
                            <div>
                              <a
                                href="https://www.facebook.com/MedicoJo.Afeef"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="d-flex ">
                                  <FiFacebook className="icons" />
                                  <h4>Dr Amjad Afeef</h4>
                                </div>
                              </a>
                            </div>
                            <div>
                              <a
                                href="https://www.instagram.com/amjadafeef"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="d-flex ">
                                  <FiInstagram className="icons" />
                                  <h4>@AmjadAfeef</h4>
                                </div>
                              </a>
                            </div>
                            <div>
                              <a
                                href="https://twitter.com/Amjadafeef"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="d-flex ">
                                  <FiTwitter className="icons" />
                                  <h4>@AmjadAfeef</h4>
                                </div>
                              </a>
                            </div>
                            <div className="d-flex">
                              <a
                                href="mailto:Afeeef.2005@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="d-flex">
                                  <AiOutlineMail className="icons" />
                                  <h4>Afeeef.2005@gmail.com</h4>
                                </div>
                              </a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <img
            src="/Images/new12.png"
            alt="underbg"
            className="img-under-count3"
          />
          <img
            src="/Images/underbg1.png"
            alt="underbg1"
            className="img-under-count4"
          />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
